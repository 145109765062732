$(function() {

    function toggleHoverClass(){
        $(this).toggleClass('header-nav__top-level-list-item--hover');
        // $(this).children('.header-nav__mega-menu').fadeToggle(200);
    }

    $('.header-nav__top-level-list-item').hoverIntent({
        over: toggleHoverClass,
        timeout: 100
    });

	// $(document).on('click', '.header-nav__top-level-list-link',  function(e){
	// 	e.preventDefault();
	// 	console.log('hovad');
	//     $(this).next('.header-nav__mega-menu').addClass('active').find('.col-md-3:first .header-nav__mega-menu-link').focus();
	// });
    //
	// $(document).on('click', '.mega-menu--toggle',  function(e){
	// 	e.preventDefault();
	// 	$('.header-nav__mega-menu').removeClass('active');
	// });

});
