/* globals cookie */

    var cookie = { //eslint-disable-line
        set: function(name, value, expires, path, domain, secure){
            var curCookie = name + '=' + escape(value) +
                (expires ? '; expires=' + expires.toGMTString() : '') +
                (path ? '; path=' + path : '') +
                (domain ? '; domain=' + domain : '') +
                (secure ? '; secure' : '');
            document.cookie = curCookie;
        },
        get: function(name){
            var dc = document.cookie;
            var prefix = name + '=';
            var begin = dc.indexOf('; ' + prefix);
            if (begin === -1) {
                begin = dc.indexOf(prefix);
                if (begin !== 0) {
                    return null;
                }
            }
            else{
                begin += 2;
            }
            var end = document.cookie.indexOf(';', begin);
            if (end === -1){
                end = dc.length;
            }
            return unescape(dc.substring(begin + prefix.length, end));
        },
        del: function(name){
            document.cookie = name + '=; domain=/; path=/; expires=' + new Date(0).toUTCString();
        },
        getArray: function(name) {
            var re = new RegExp('[; ]*' + name + '=([^\\s;]*)');
            var sMatch = document.cookie.match(re);
            return JSON.parse( sMatch ? unescape(sMatch[1]) : '[]' );
        }
    };

    var cookieInfo = {
        config: {
            elSelector: '.js-cookie-info',
            disabledClass: 'cookie-info--disabled',
            ctaSelector: '.js-cookie-cta',
            dataAttrs: {
                cookieName: 'data-cookie-name',
                acceptedVal: 'data-cookie-accepted-val'
            }
        },
        init: function() {
            var el = document.querySelector(cookieInfo.config.elSelector);
            // adjust basket position

            // Check if the cookie info wrapper exists and the cookie is not yet set
            if (el && !cookieInfo.isCookieSet(el)) {
                var btnEl = el.querySelector(cookieInfo.config.ctaSelector);
                // Add cta click listener that will set the cookie when clicked
                btnEl && cookieInfo.handleClick(btnEl, el);
            }
            // Check if the cookie is already set on load. If so, lets hide the cookie info wrapper
            el && cookieInfo.isCookieSet(el) && cookieInfo.hideEl(el);
        },
        handleClick: function(btn, wrapperEl) {
            btn.addEventListener('click', function(e) {
                e.preventDefault();
                cookieInfo.setCookie(wrapperEl);
                cookieInfo.hideEl(wrapperEl);
            });
        },
        isCookieSet: function(wrapperEl) {
            return cookie.get(wrapperEl.getAttribute(cookieInfo.config.dataAttrs.cookieName));
        },
        setCookie: function(el) {
            var cookieName = el.getAttribute(cookieInfo.config.dataAttrs.cookieName),
                cookieVal = el.getAttribute(cookieInfo.config.dataAttrs.acceptedVal);
            cookie.set(cookieName, cookieVal, false, '/', window.location.hostname);
        },
        hideEl: function(el) {
            // Hide the cookie info wrapper
            el.style.top = '-' + el.clientHeight + 'px';
            // adjust basket position
            var basket = document.getElementsByClassName('basket');
            if (el && basket && basket[1]) {
                basket[1].style.top = '0px';
            }
        }
    };

    jQuery(window).on('load', function() {
        cookieInfo.init();
    });


