$(function() {
    if($('#mp-menu').length){
        new mlPushMenu(document.getElementById('mp-menu'), document.getElementById('mp-menu-button'));
    }
});


$(document).on('click', '.header__nav-button', function(e){
	e.preventDefault();
	// console.log('clicked');
});
