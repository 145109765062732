$(function() {
    $('.film-carousel__list').slick({
        infinite: true,
        slidesToShow: 1,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 767, // potentially 543
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4
                }
            }
        ]
    });
});
